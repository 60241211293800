import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/fbert/OneDrive/Documents/wwwBertramFamilyCom/GatsbyJS/src/components/layout-mdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div style={{
      "marginTop": "100px",
      "marginBottom": "100px",
      "display": "flex",
      "flexFlow": "row wrap",
      "justifyContent": "space-around"
    }}>
      <div style={{
        "flex": "0 0 200px"
      }}>
        <p><a parentName="p" {...{
            "href": "/felix/"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "400px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEE0lEQVQ4y5WUeUzTZxzGn/qri8mmQIxQFlvu6gZ0tRwF1iIt9KCwCrSlnCGGIpfjmCwSssXMbRljDlCRKCSTQ2wLtCsVwRYEh1xOxGQxc1CZicqWLGPJ/gA3xvYubbYsGTDYk3yTN8/7fD/JewL/0rvKEFSImHjQfx7kyTgt8yCl1YV7tJfw91ZpWBA3FCXS6wpkKBd4o0LEwpYqfp2BcrHvjpzgXcgP96huKFSQL4yN5Et7J2mq0pKj3Jcqsw7SUSb2pYpjvLYBjPHEpROZVL1OgRolt3nx7nVCyK8rhJCVpcX75GRy8JkPc4WoL0miF/D3bQ0sivaEpaGaPtZ6Cj2ndbUtn3xAhkf6V+03B1fbWs6SntrC0/ZLNTDUldMLorYBLBMy4BjpoRZv9+DpWLfm63E7mZkaXpt/eGdtbuYWWZwypX43bcajMRN1jL+NJZcLGVh2zNB++moWjycm9izPTzybn+4njrsD5Odvbj957nC8+IvjIVYWZrEtoG9cK3hFcxg026jR/hF8O25TzA5dI/eHrpFn0zbplP0WblqHKF7Z92CJOv4bxhB0QFpqA1thBFALoAJecfpP+endRHXCRkK11o/dkwYRUzpKc2Zkb9rgJWzbHBic0usqrsZERWZbwNWYtQeS9CQoyfh7YuXwH9G5feSVN7rVr6l6EaY1UxyVCaFppo1hkdl9OJRuQYDMQIN3E7CzbhdPa37MEnWQkBTjmvad0bWwDDPhqk0LcGt8AZ7nEaQw0iKyreDnWNcD44tvICLLipLGaZq6ZhTxxYNNMXnWpb1RV34MTrUQ7alJEpJm+SEy27IkPW47q6oewdGPJmjRef2QlNo2ABbdADtJT+OoehGV26eN1Q2s+osvKAN5OYti9Xskr7KZiFLefsqKbVbG5l//LSbPquFqel09CSUbADkqEy0swwJehoXB1ZiIr0TfGn58BaGh3EexsfFEoUglAoFoTnxyDT4J+s+cmYhs6z7nVnE1Zto64MuHO8EUdcFfZqAYgnbNfpGR2dxHIE/OvJMgUzkkctVCYnLmZL1hGUyRgcUQtKsDE407mOIuV+867Y+7Apa4C35SA7xju5z/Dian7iE9s2BQm3XMrsnQDWdkF/YPD48BeN+V8ZcbwIq/6updJz/JVeep4dUjJviIL1NiZRVdrdUhQZbamSBNNcdLjlikclWbKj0fIuVbO33EbRRH/TkOJPfAT6rf7CYOABhzjQLZHHogm4OAoNCGQDanOYjNuRgQFHrG6Tnn/skPbP382g3j4IUL6IfCBIjgiy7wIoSXeeHCtsgo0Tmn55y79+A5ti2GNxOEEFdJ5GktwrjEduFheac0UXXxb5/hzcL/kru7B3bv3kMBcAPg8Ve5OT03d49NG/8EFbmbPiu/LNAAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Felix Bertram",
                "title": "Felix Bertram",
                "src": "/static/534d8c5869186efc29670a51935e0292/e17e5/felix_med_hr.png",
                "srcSet": ["/static/534d8c5869186efc29670a51935e0292/5a46d/felix_med_hr.png 300w", "/static/534d8c5869186efc29670a51935e0292/e17e5/felix_med_hr.png 400w"],
                "sizes": "(max-width: 400px) 100vw, 400px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span></a></p>
      </div>
      <div style={{
        "flex": "0 0 200px"
      }}>
        <p><a parentName="p" {...{
            "href": "/birgit/"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "400px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADyElEQVQ4y5WSXUyTdxjFz/t27GbJFmK225nFLE4k0DrroDCJjMnko6WWthQVtAwBBx0D2YYMAuIyGKgUSlE+BAZttEURKG1pebsWsbWFiThFHSQuGc5swLK4MFZi/ku7cbPYgSd5bp5znl/OxQP8Rxa1FKbGVNguHAHTlkE7urMxMXQCd8aq4dTl4PqlY7RTl49xTb7Px7qyNEtgahTB3pVFM+2ZcHRnh3r6S7qnzF90uvo+CnHpC+DSF9BjPTkYPSdbHzjSLIG1JZ0yKUWwqKRvjLZnLkwMlZKH0xpy16H8xaIWbh5p4sOlK6Q2BPQ1NDaKWFdq3ofhrEB5vTeX/D4/tkwIWf51zkws52SnR1okcOqLWcz5Axtr+MDxDfXHzTa4NXkXB7tqiNvNrDpd1lVmqJPc6i/RkMcMfvreSFnV0vWBoy1SLNy+xnpyZwyzVnXjrMtEJpwm7+w9l3fOYyazVtWZxSkDHk0aWBa1ZGMNfSKEwNb2IbM4bSEPmctPHzuHny5MjxBba9agd37F769l1wNS5iYRHB3yTcMNKfMzlsZHv9murCzZr67cY5p/Hm5I+dHRIX/VrBL5s+sCr9Zm4fSBTtSl3aUJQVCdtCv6vML6l/7LieWWPB1v8T5erOYTamkO0Fcp/h92nHcZqsMERRFaKMIHYGkHyuIMk5/FDJAm+Q1ySmB1ry6U+rOF3D605RN8+m7/s2EVm22oFdugzvagLs0e1JA5jq9EFsXne/pJefyQt7NoyluVZCSnBOb8+nQ76mT2IF/WdxMQWC+z48zBMVqZOY6zB6+92SR3egp2aLXlHxhIR+FNUho70Ks8PO5RH72xRSV3oiFjnK6XOQIDa1IZqjzOgIq9xuB6mf27WjGTmxva030idoB0H79NKhNMrVWJprz6dPtk5T7zKxV7jb6GVEBgZYKRdTLJjKoks7gsbtC6HeVBBVzNDwXcXtKqcJOv05kZAEFlcYPMyWTz/urkEVQlmuhnA1+3+X8LeGltRb+XIEBi/KFjIsHR+xJR7gOhICsnQSj2e2uhf24CNHwNmYhCBdgoQWoRn94vOYJ9fHGKUJLxbUrqIXuCQJqcKs2CuIRPb8cniEAZXkZa4Lcpjuzzz8cRWvDYfJrzdjTC2LzYcA7vUjiHpwvn8GI4O6MRxRHQhRFaFPP6/LMhvRWyg966jY0wdmRkSCi3JyR0Z28YO3LX1m0cv4fnVf/wLSz9ScB9J0YUvTteE7U7Xrsrco/wySqBwTrz3DzQNAWKol4AEAxg078TTFEUi6YDF/wbTAfB9glbXiEAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Birgit Bertram",
                "title": "Birgit Bertram",
                "src": "/static/1c4df69be94accbfb1ef825bf9db47e3/e17e5/birgit_med_hr.png",
                "srcSet": ["/static/1c4df69be94accbfb1ef825bf9db47e3/5a46d/birgit_med_hr.png 300w", "/static/1c4df69be94accbfb1ef825bf9db47e3/e17e5/birgit_med_hr.png 400w"],
                "sizes": "(max-width: 400px) 100vw, 400px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span></a></p>
      </div>
      <div style={{
        "flex": "0 0 200px"
      }}>
        <p><a parentName="p" {...{
            "href": "/tom/"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "400px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADu0lEQVQ4y53Ua0ybZRQH8P/baqIfZ8gS/WIyRxaUm2yZMjrYEm6zYxQY9AaDrQHLLglxxjkdJjo1UZONrdBRoKWwtpRy6QXoCpZSCgXK1A2WgLIpMMlCHAxs395A9pia+JFS/Sfny8l5fjkfTh5gmwxIuej5JgO6S/GwyoSwK0VYn9dh1vYVRlRi/KeEMJf1OiwNZzBwi8+YNF6mfptSUJOGjxhz9+UIkseY6KyKDLPW8zDQWIb+egH6pTzKUluIYZUYU4NX4eq+CIe6khppOw9NdRqst3g7g53fctD+9WGYJQVU34089NfxMgabSk1O7QXjpOFShkNdCXtrOdV8MSYy0HyzEP11RYze6ydgvpHPsjYIyERHFfnZeY3cu3OFDDaWpFgbijGqrmREBH4v5cHeXMEclp+GrbGk1tX9JXnyyOZfWZ7yTw/LiF1RJnOqxBjTfcCMGHxgrmH+bq/FA/0VicOkJXMP7288mp/dGB/qJdPGTxtX7t3GL8OKyECrlI8Fh4Y5P9SKXweVx/6YtpHHMyNba0+mtp7OOsj8cEv2okOFRaeWMa4q3hm01wtAluYxNCTF7F0N1mdGzAsuC1l0Wcj6zEjP3KQGPzhbQJYWYPyuYGdwSZYDQgjcDjkjOKqEe1T52tMByfqKo2nV82Pnq5s/dcE91soIzTyU5YXHAloufH1n8RcZBa3jM91NWfDI2Vc98uPEZ64kdFvR57T+FLx9FcxNjx6+O+fg13K3B726YvhM5fB2lTK9Pe/D21ESTytOEFrJIX7bh8SrLnpOa7ixXoMIPv1ppr+vEr7OU2FALR++zlLK31GMDVsV/JbzxtW6zIk1RT6hbR9vrSnyxv3mswavKh/BXjH8hjOUt10YBmwXYrUhBx5tMdY1fAOxlNVE7X5DyM3NIWKRiMS9fZRPeoSSP9sEXW41D6uSDPg6SrYH6eZcBLpK8XzmGvVMxv6M3I3e9XpCem5KGtt9JD3XvSfp2HFi2xv1TMauJmQRAbMYtJITZsPWAgTauNg0iUAMHFSLipCXL2DzhRVOnqB8rLCwJPuLCh5Idy62rBcQ7BDCe/tkmA2VHARa87DcxEHX5fdejD6Yg2RW5sFDrEzjIVamKSU1e//edzjQf8J+YbmlCEHNSdAtYU6HvnkEQelR7Il6CQmxiVT0vnjEJbz7ZkzsAU3MWwfa4hOT94V6CXGJVPTul/+ZddekRfYvhg43VIlJyVms1CwVKzVLnbQ/Jf3f/v8Nk6IYrwCIChVFMXaFeuFe/A2GauKQ07mXMgAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Tom Bertram",
                "title": "Tom Bertram",
                "src": "/static/e960c932f8ec95b5abaf06842ff3c086/e17e5/tom_med_hr.png",
                "srcSet": ["/static/e960c932f8ec95b5abaf06842ff3c086/5a46d/tom_med_hr.png 300w", "/static/e960c932f8ec95b5abaf06842ff3c086/e17e5/tom_med_hr.png 400w"],
                "sizes": "(max-width: 400px) 100vw, 400px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span></a></p>
      </div>
      <div style={{
        "flex": "0 0 200px"
      }}>
        <p><a parentName="p" {...{
            "href": "/uwe/"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "400px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEBUlEQVQ4y5WTa0wbVBTH/21xEUeU+MkYwwd0QoEWCt2gHaWlQGhLaRl0QAcMsQSBIqP0QSmlZbS0gDAYDBivkbLAykM2YWwRxY3Fuewhhrg4EzRDnZuvD05hOpi7pmR+ERT2T07uyc3//nJOzrnAv1Ssfhtz4x1YnG6i3L/cD3K7D8uf2MN/m69hPVx6D1/O2bEwY8PksBOacj22lAf4xdkWnOg9h+8IoTy8ZBt8MO8ky0sDZOWr4wMeT/6eYMy/37Du3Rbw6lQf7crpXpx2dRjuzdSSX2+2PLr/w+xfS9fbSVt9hebksUoM9xymFRaptwYWFZeAkEkKIS6MtVeeH2szkoZ606rTYV4d7a4i7k7TFPlFA3KrmFJltmyvQnLTTiWLDnzuNtkX3AZyprds7aMR09pnY0Zya8JSQ+ZNIJcNVHudc3vAe9NW6t1JK8jF2l1/XKh+/OmpEnLnQxNZuWB+RC5a/X86W4Ofz9dQbXbH9oCrc9X484yJQq7Z8GDG6vp2VEu+GdGS5VlHP5k1YWVcT/n9SiNqbXVbA0P0MYgzCWHpyqXpWjJR0qpkN/epiGui4rFtRM96x61F01AZLdEiArNC8P+wV0sjMbY4hNcPcZDfkYNT19ufYRqFN/y1MUTelUfi6lOv0lXBXs+KfBGo4eLEQi92lXE2hwnqZEhuUaJ0VIek5gwv+dEs7D2cpGfo+GSPKWFNPVS2xrfJCNucoElsVEB6JNPr0KgOstYDiHXINwLDK+PAtycjsVFBVXTkes7XkluV37+iDr/NtsST0nd1hG1J+FrclH4n7dhBf2W3CpLmDKoHtrsqYSOQZRSCWyNez7UTRig6cz8WN6cfeUkSPM4vlxN1l55wCyVuoTOlNb0r71LRcNm6l2eTIsIUvxEYZRUht7+QktqWgzhHygjXKrlbrTOAHhDuFsRKSUrqQRITLR6aOTkNjlX8Y5xz33BKaxaUx9+kcGskm0+XoeODron25Gq/gojkvdlS8ISSbp5Ashwbn7zCj5d28nJk8HsrQh6i5xcHlnERVB4NhmGTaYc+WQFBrQxsTwuVzyMrsxj7FLkN6cr8a4oM1Y209DxHtlINGF8AuyoBXLMILVNOhBmFm1eY21kAjlnkMVCj9ifukKYcgEiyv0IqV84kyZQfSKQZOllqNqIyRDtYRiF1d2U8xI40MAz8TfaGAH5q9nq8nM1AQBCLFhgUjgA6q4AeHOGiB0cMBtBZqvW7IBbN4/nHD4Vi6x/DCI2khTAjwYqIVjHDOC5mGGeQxea9wQiNBDMsioanVa2jm9IzcA782CQtTyB28/jiEUGcVNMzMI1aZw/lqYE7d/rA29vbC4AvgBefhK+393M0Hx+f/3z4N/u8l1b6kf8dAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Uwe Bertram",
                "title": "Uwe Bertram",
                "src": "/static/764b2902a0e630aa0dafe654a2624849/e17e5/uwe_med_hr.png",
                "srcSet": ["/static/764b2902a0e630aa0dafe654a2624849/5a46d/uwe_med_hr.png 300w", "/static/764b2902a0e630aa0dafe654a2624849/e17e5/uwe_med_hr.png 400w"],
                "sizes": "(max-width: 400px) 100vw, 400px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span></a></p>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      